import * as Yup from "yup";

export const registrationValidationSchema = Yup.object().shape({
        email: Yup.string()
                .email("Invalid email")
                .required("Email is required"),
        password: Yup.string()
                .min(8)
                .matches(/^(?=.*[A-Z])((?=.*[a-z])(?=.*[0-9])|(?=.*[!"#$%&'()*+,-.\/:;<=>?@[\]\^_`{\|}~]))/, "Password must contain at least one lowercase letter and at least one uppercase letter, plus at least one digit or special character")
                .required("Password is required"),
        confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], "Passwords must match")
                .required("You must confirm your password")
});

export const loginValidationSchema = Yup.object().shape({
        email: Yup.string()
                .email("Invalid email")
                .required("Email is required"),
        password: Yup.string()
                .required("Password is required")
});

export const validateSignIn = (email, password, error, next) => {
        if (!email || !password) {
                error('Complete all fields to continue')
                return
        }
        if (!/^(?=.*[A-Z])((?=.*[a-z])(?=.*[0-9])|(?=.*[!"#$%&'()*+,-.\/:;<=>?@[\]\^_`{\|}~]))/.test(password)) {
                error(`Password must contain at least one lowercase letter and at least one uppercase letter,
                 plus at least one digit or special character`)
                return
        }
        if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
                error('Invalid email. Ex: john@doe.com')
                return
        }
        next('Sign In Fields OK . .')
}

export const validateRegistration = (email, password, cpassword, error, next) => {
        if (!email || !password) {
                error('Complete all fields to continue')
                return
        }
        if (password !== cpassword) {
                error('Your passwords do not match')
                return
        }
        if (!/^(?=.*[A-Z])((?=.*[a-z])(?=.*[0-9])|(?=.*[!"#$%&'()*+,-.\/:;<=>?@[\]\^_`{\|}~]))/.test(password)) {
                error('Password must contain at least one lowercase letter and at least one uppercase letter, plus at least one digit or special character')
                return
        }
        if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
                error('Invalid email. Ex: john@doe.com')
                return
        }
        next('Registration Fields OK . .')
}