import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View, SafeAreaView } from 'react-native'
import { createDrawerNavigator } from '@react-navigation/drawer';
import Center from '../Prebuilt/Center'
import { howManyUsersOnline } from '../Util/howManyUsersOnline'
import ChannelScreen from '../ChannelScreen/ChannelScreen';
import ChannelListDrawer from '../ChannelList/ChannelListDrawer';
import { UserContext } from '../../Context/UserContext';
import users from '../../../data/testUsers';
import channelSections from '../../../data/channelSections';
import { establishChatSocket } from '../../services/http/socket/webSocket';
import { getUser } from '../Util/getUser';
import { SocketContext } from '../../Context/SocketContext';


import { styles } from '../../styles/App.Styles';


const Drawer = createDrawerNavigator();
const testUser = {
    name: 'Bill',
    id: '3',
    isOnline: true
}
const allUsers = users;

export default function AppScreen() {
    const [currentUser, setCurrentUser] = useState();
    const [socket, setSocket] = useState();
    const [userMemberships, setUserMemberships] = useState();
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function getCurrentUser() {
            const socketUser = await getUser();
            await establishSocket(socketUser);
            setCurrentUser(socketUser);
        };
        getCurrentUser();
    }, []);

    async function establishSocket(currentUser) {
        const newSocket = await establishChatSocket(setSocket);
        newSocket.on('connect', () => {
            console.info('Connected to chat server');
        });
        newSocket.emit('login', currentUser);
        newSocket.on('userMembership', (membership) => {
            setUserMemberships(membership);
            membership.roomIDs.forEach(id => {
                const titleID = { id: id, name: id }
                channelSections[3].data.push(titleID);
            })
        })
        //not the best way to handle this, but socket is created before the data is fetched.
        //TODO, find a better method to handle this other than setting a timeout
        setTimeout(() => setLoading(false), 1000);
    };

    const onlineUsers = howManyUsersOnline(allUsers);

    if (loading) {
        return <Center><ActivityIndicator /></Center>
    }


    // console.log(userMemberships)

    return (
        <SocketContext.Provider value={socket}>
            <UserContext.Provider value={userMemberships}>
                <Drawer.Navigator
                    openByDefault
                    drawerContent={props => <ChannelListDrawer {...props} channels={channelSections} user={testUser} appStyles={styles} userMemberships={userMemberships} />}
                    initialParams={{ user: testUser }}
                    drawerStyle={styles.drawerNavigator}>

                    <Drawer.Screen name="ChannelScreen" component={ChannelScreen} initialParams={{ user: testUser, channels: channelSections, appStyles: styles, onlineUsers, allUsers, socket }} />
                </Drawer.Navigator>
            </UserContext.Provider>
        </SocketContext.Provider>
    )
}
