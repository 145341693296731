import { getUser } from "../../../components/Util/getUser";
import { getSecureAxios } from "../axios";
import BlindlyGroupsApi from "../config";
import chatRoutes from "./routes";

const SERVICE = BlindlyGroupsApi.chat;

export async function getChatRoomData(roomID) {
  const user = await getUser();
  const data = new Promise((resolve, reject) =>
    getSecureAxios()
      .then((axios) =>
        axios.post(`${BlindlyGroupsApi.chat}/${chatRoutes.getChatRoomData}`, {
          user,
          roomID,
        })
      )
      .then((res) => resolve(res.data))
      .catch((err) => {
        console.error("Error Getting Room Data", err);
        reject(err);
      })
  );
  return data;
}
