import React from 'react';
import { SafeAreaView, View, Text } from 'react-native'
import { useTheme } from 'react-native-paper'
import AppHeader from '../components/Header/AppHeader'
import Center from '../components/Prebuilt/Center'

export default function ThreadssScreen(props) {
  const paperTheme = useTheme()
  const usersOnline = 0

  return (
    <View style={{ flex: 1 }}>
      <AppHeader {...props}
        title={'Threads'}
        leftIcon={'dots-vertical'}
        leftAction={() => props.navigation.toggleDrawer()}
      />
      <Center><Text style={{ color: paperTheme.dark ? '#fff' : '#000' }}>Threads</Text></Center>
    </View>
  )
}