import { getAccessToken, getRefreshToken, clearTokens } from './AuthRoutes';
import { setTokens } from './AuthRoutes';


// USE AUTHCONTEXT DISPATCH FUNCTION, GRAB ACCESSTOKEN, SEND TO AUTHCONTEXT STORAGE
export const restoreAccessToken = async (dispatch) => {
  const restoreToken = async () => {
    let accessToken;
    try {
      accessToken = await getAccessToken();
      refreshToken = await getRefreshToken();
      if (accessToken && refreshToken) {
        await setTokens(accessToken, refreshToken);
        dispatch({ type: 'SIGN_IN', val: accessToken })
      }
    } catch (err) {
      console.error("Error restoring access token");
    }
  }
  restoreToken()
}