import React from 'react'
import { StatusBar, ScrollView, StyleSheet, View, Text } from 'react-native'
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';
import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons'
import { useTheme, Appbar, Avatar, Button, Card, Title, Paragraph } from 'react-native-paper'
import AppScreen from '../AppScreen/AppScreen'
import { useAuth } from '../../Context/AuthContext'
import ProfileScreen from '../../screens/ProfileScreen'
import ChatScreen from '../../screens/ChatScreen'


const Tab = createMaterialBottomTabNavigator()


export default function Tabs(props) {
  const [authState, authAction] = useAuth()
  const [loading, setLoading] = React.useState(false)
  const paperTheme = useTheme()

  // TEMP
  const doSignOut = () => {
    setLoading(true)
    authAction.signOut()
    console.log('You are now OFFLINE!')
  }

  return (
    <Tab.Navigator
      initialRouteName='Chat'
      style={{ backgroundColor: paperTheme.background }}
      barStyle={{ backgroundColor: paperTheme.background, height: 90, paddingTop: 5 }}>

      <Tab.Screen name='Chat' children={props => <ChatScreen {...props} />}
        options={{
          tabBarIcon: ({ color }) => (
            <AntDesign name="wechat" size={24} color="white" />
          )
        }} />

      <Tab.Screen name='Profile' children={props => <ProfileScreen {...props} loading={loading} />}
        options={{
          tabBarIcon: ({ color }) => (
            <MaterialCommunityIcons name="face-profile" size={24} color="white" />
          )
        }} />
    </Tab.Navigator>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 10,
  }
})