import React, { useContext } from "react";
import {
  SafeAreaView,
  View,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
  Text,
} from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import {
  createDrawerNavigator,
  DrawerItem,
  DrawerContentScrollView,
} from "@react-navigation/drawer";
import { AntDesign } from "@expo/vector-icons";
import AppTabs from "./components/Navigation/AppTabs";
import SignUpScreen from "./screens/SignUp";
import LoginScreen from "./screens/Login";
import AppScreen from "./components/AppScreen/AppScreen";
import { useAuth } from "./Context/AuthContext";
import { useSocket } from "./Context/SocketContext";
import { useStore } from "./Context/StoreContext";
import { useUser } from "./Context/UserContext";
import Center from "./components/Prebuilt/Center";
import AppDrawerContent from "./components/Navigation/DrawerContent";
import ChatScreen from "./screens/ChatScreen";
import ProfileScreen from "./screens/ProfileScreen";
import DMScreen from "./screens/DMScreen";
import ThreadsScreen from "./screens/Threads";
import SettingsScreen from "./screens/Settings";
import SupportScreen from "./screens/Support";
import ChooseNamespace from "./screens/ChooseNamespace";
import CreateChannelForm from "./screens/CreateChannelForm";
import ChannelChatScreen from "./screens/ChannelChatScreen";

const AuthStack = createStackNavigator();
const AppStack = createStackNavigator();
const AppDrawer = createDrawerNavigator();

// TODO : Strange behavior -> For some reason can't clear namesapce wihtout throwing errors
// -> connecting two phones to the same namespace doesn't connect the other simulator.
// not getting membership when the second phone connects

// Why is this re-redering so much???!!!Î
//  -> this is why...everytime context updates the entire app is updating. may need to migrate to  redux for socket to avoid // this

export default function App() {
  const [authState] = useAuth();
  const [storeState] = useStore();
  const [socketState, socketAction] = useSocket();
  const [userState] = useUser();

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => setLoading(false), 500);
  }, []);

  const AuthNavigator = () => (
    <AuthStack.Navigator>
      <AuthStack.Screen name="Sign In" component={LoginScreen} />
      <AuthStack.Screen name="Sign Up" component={SignUpScreen} />
    </AuthStack.Navigator>
  );

  const AppDrawerNavigator = () => {
    if (storeState.namespace !== null)
      // can we connect the socket here?
      return (
        <AppDrawer.Navigator
          drawerContent={(props) => <AppDrawerContent {...props} />}
          drawerContentOptions={{
            activeTintColor: "blue",
            labelStyle: {
              color: "white",
            },
          }}
        >
          <AppDrawer.Screen
            name="Chat"
            children={(props) => <ChatScreen name="Blindly Chat" {...props} />}
          />
          <AppDrawer.Screen
            name="Profile"
            children={(props) => <ProfileScreen name="Profile" {...props} />}
          />
          <AppDrawer.Screen
            name="DirectMessages"
            children={(props) => <DMScreen {...props} />}
          />
          <AppDrawer.Screen
            name="Threads"
            children={(props) => <ThreadsScreen {...props} />}
          />
          <AppDrawer.Screen
            name="Settings"
            children={(props) => <SettingsScreen {...props} />}
          />
          <AppDrawer.Screen
            name="CreateChannelForm"
            children={(props) => <CreateChannelForm {...props} />}
          />
          <AppDrawer.Screen
            name="ChannelChatScreen"
            children={(props) => <ChannelChatScreen {...props} />}
          />
          <AppDrawer.Screen
            name="Support"
            children={(props) => <SupportScreen {...props} />}
          />
        </AppDrawer.Navigator>
      );
  };

  if (loading)
    return (
      <Center>
        <Text style={styles.welcomeText}>👋</Text>
        <ActivityIndicator />
      </Center>
    );

  if (storeState.userNamespace === null && authState.userToken !== null) {
    return <ChooseNamespace />;
  }

  return (
    <>
      {authState.userToken === null ? (
        <AuthNavigator />
      ) : (
        <AppDrawerNavigator />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  welcomeText: {
    marginBottom: 5,
    fontSize: 36,
  },
});
