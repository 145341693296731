import React from "react";
import {
  ScrollView,
  Alert,
  StyleSheet,
  SafeAreaView,
  View,
  Text,
} from "react-native";
import { useTheme, Button, TextInput } from "react-native-paper";
import Center from "../components/Prebuilt/Center";
import AlertView from "../components/Prebuilt/AlertView";
import { validateRegistration } from "../validation/validationSchemas";
import {
  setTokens,
  getAccessToken,
  clearTokens,
} from "../services/auth/AuthRoutes";
import { useAuth } from "../Context/AuthContext";
import { registerUser } from "../services/http/blindlyGroups/AuthDao";

export default function SignUpScreen(props) {
  const [authState] = useAuth();
  const [userName, setUserName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [cpassword, setCPassword] = React.useState("");
  const [alert, setAlert] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const paperTheme = useTheme();

  const handleReset = () => {
    setEmail("");
    setPassword("");
    setCPassword("");
  };

  const handleRegistration = () => {
    setAlert(null);
    validateRegistration(
      email.trim(),
      password,
      cpassword,
      (error) => {
        setAlert(error);
        setSuccess(false);
      },
      (success) => {
        setSuccess(true);
        const doRegister = async () => {
          try {
            const response = await registerUser(email, password);
            console.log("REGISTERED!", response);
            handleReset();
            props.navigation.navigate("Sign In", { email: email });
          } catch (e) {
            let message = "Registration failed. Try again";
            setAlert(message);
            console.log(message, e);
            setSuccess(false);
          }
        };
        doRegister();
      }
    );
  };

  return (
    <ScrollView style={styles.container}>
      <Text
        style={[
          styles.inputTitle,
          { color: paperTheme.dark ? "#fff" : "#000" },
        ]}
      >
        Register with your Email
      </Text>

      <AlertView title={alert} onClose={() => setAlert(null)} />

      <TextInput
        testID="signUpEmail"
        label="Email"
        value={email}
        style={styles.textInput}
        onChangeText={setEmail}
        autoCapitalize={"none"}
      />
      <TextInput
        testID="signUpPassword"
        label="Password"
        value={password}
        style={styles.textInput}
        onChangeText={setPassword}
        secureTextEntry
        textContentType="oneTimeCode"
        autoCapitalize={"none"}
      />
      <TextInput
        testID="signUpConfirmPassword"
        label="Confirm Password"
        value={cpassword}
        style={styles.textInput}
        onChangeText={setCPassword}
        secureTextEntry
        textContentType="oneTimeCode"
        autoCapitalize={"none"}
      />

      <Button
        testID="signUpBtn"
        title="Sign In"
        mode="contained"
        style={styles.registerButton}
        loading={success}
        onPress={handleRegistration}
        disabled={success}
      >
        Complete Registration
      </Button>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    margin: 10,
  },
  inputTitle: {
    fontWeight: "600",
    margin: 10,
  },
  textInput: {
    margin: 5,
  },
  registerButton: {
    margin: 10,
    marginTop: 15,
  },
});
