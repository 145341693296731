import { getUser } from "../../../components/Util/getUser";
import { getSecureAxios } from "../axios";
import BlindlyGroupsApi from "../config";
import chatRoutes from "./routes";

const SERVICE = BlindlyGroupsApi.chat;

export async function createNewNsp(name) {
  const newNSP = new Promise((resolve, reject) =>
    getSecureAxios()
      .then((axios) => axios.post(`${SERVICE}/nsp/create`, { name }))
      .then((res) => resolve(res.data))
      .catch((err) => {
        console.error("Error Getting User Membership", err);
        reject(err);
      })
  );
  return newNSP;
}
