import React, { createContext } from "react";
import { restoreAccessToken } from "../services/auth/RestoreToken";
import { getAccessToken, clearTokens } from "../services/auth/AuthRoutes";
import { useStore } from "./StoreContext";

export const AuthContext = createContext({});
export const useAuth = () => React.useContext(AuthContext);

export default function AuthProvider({ children }) {
  const [authState, authDispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "SIGN_IN":
          return {
            ...prevState,
            userToken: action.token,
          };
        case "SIGN_OUT":
          return {
            ...prevState,
            userToken: null,
          };
        case "RESTORE_TOKEN":
          return {
            ...prevState,
            userToken: action.token,
          };
      }
    },
    {
      userToken: null,
    }
  );

  React.useEffect(() => {
    // PASS AUTHCONTEXT DISPATCH TO RESTORE ACCESS TOKEN
    restoreAccessToken(authDispatch);
  }, []);

  const authAction = {
    signIn: async (accessToken) => {
      if (!accessToken) {
        console.log("AUTHCONTEXT", "Missing required accessToken");
        return;
      }
      authDispatch({ type: "SIGN_IN", val: accessToken });
    },
    signOut: async () => {
      clearTokens();
      setTimeout(() => {
        authDispatch({ type: "SIGN_OUT" });
      }, 500);
    },
  };

  return (
    <AuthContext.Provider value={[authState, authAction]}>
      {children}
    </AuthContext.Provider>
  );
}
