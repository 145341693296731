import React, { useContext, useEffect, useState } from "react";
import { SafeAreaView, StyleSheet, View } from "react-native";
import {
  Card,
  Headline,
  Text,
  Button,
  TextInput,
  Paragraph,
  Subheading,
} from "react-native-paper";
import { getUserMembership } from "../services/http/chat/getUserMembership";
import { getUser } from "../components/Util/getUser";
import { UserContext, useUser } from "../Context/UserContext";
import { FlatList, ScrollView } from "react-native-gesture-handler";
import { createNewNsp } from "../services/http/chat/createNewNsp";
import { StoreContext } from "../Context/StoreContext";

export default function ChooseNamespace(props) {
  const [loading, setLoading] = useState(true);
  const [storeState, storeAction] = useContext(StoreContext);
  const [userState, userAction] = useContext(UserContext);
  const [userMemberShip, setUserMembership] = useState([]);
  const [invites, setInvites] = useState([]);
  const [name, setName] = useState();
  const [nsps, setNsps] = useState([]);

  useEffect(() => {
    async function getMembership() {
      const user = await getUser();
      const member = await getUserMembership(user);
      userAction.setUser(user);
      setUserMembership(member);
      setInvites(
        member.invites.length > 0
          ? member.invites.filter((i) => i.inviteType === "nsp")
          : []
      );
      setNsps(member.nsps);
      return member;
    }
    getMembership().then((mem) => {
      console.log("MEM", mem);
      setLoading(false);
    });
  }, []);

  const createNamespace = async () => {
    const nsp = await createNewNsp(name);
    storeAction.setUserNamespace(nsp.nsp);
  };

  if (loading) return null
  
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView style={{ flex: 1 }}>
        {userState.user && (
          <Paragraph>USER GUID: {userState.user.guid}</Paragraph>
        )}
        {userMemberShip && invites.length > 0 && (
          <FlatList
            data={invites}
            keyExtractor={(item, index) => item.guid + index}
            renderItem={({ item }) => {
              return (
                <Card style={styles.cardContent}>
                  <Headline>You've been invited to a namespace</Headline>
                  <Subheading>Just select the id to join</Subheading>
                  <Button
                    onPress={() => storeAction.setUserNamespace(item.inviteID)}
                    style={styles.button}
                  >
                    {item.inviteID}
                  </Button>
                </Card>
              );
            }}
          />
        )}
        {userMemberShip && nsps.length === 0 && (
          <>
            <Headline style={styles.header}>
              Create a Namespace to get started
            </Headline>

            <TextInput
              label="Namespace"
              testID="NamespaceInput"
              value={name}
              onChangeText={(t) => setName(t)}
            />
            <Button style={styles.button} testID="createNamespaceBtn" onPress={() => createNamespace()}>
              Create
            </Button>
          </>
        )}
        {userMemberShip && nsps.length > 0 && (
          <View style={styles.cardContainer}>
            <Headline style={styles.header}>
              Select a Namespace to Enter
            </Headline>
            <FlatList
              data={nsps}
              keyExtractor={(item) => item}
              renderItem={({ item }) => {
                return (
                  <Card style={styles.cardContent}>
                    <Button
                      onPress={() => storeAction.setUserNamespace(item)}
                      testID={`NamespaceBtn_${item}`}
                      style={styles.button}
                    >
                      {item}
                    </Button>
                  </Card>
                );
              }}
            />

            <Headline style={styles.header}>Or Create A New Namespace</Headline>

            <TextInput
              label="Namespace"
              testID="AdditionalNamespaceInput"
              value={name}
              onChangeText={(t) => setName(t)}
            />
            <Button
              style={styles.button}
              testID="AddNewNamespaceBtn"
              mode="contained"
              onPress={() => createNamespace()}
            >
              Submit
            </Button>
          </View>
        )}
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  cardContainer: {
    margin: 10,
    flex: 1,
    borderWidth: 1,
    padding: 10,
  },
  header: {
    color: "black",
    // alignSelf: "center",
    padding: 20,
  },
  button: {
    color: "black",
    margin: 2,
  },
  cardContent: {
    flex: 1,
    borderWidth: 1,
    padding: 10,
    margin: 10,
    flexDirection: "row",
    height: 'auto'
  },
});
