import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Paragraph,
  TextInput,
  Button,
  FAB,
  Appbar,
  useTheme,
  Modal,
  Portal,
  Headline,
} from "react-native-paper";
import { View, StyleSheet, ScrollView, FlatList } from "react-native";
import AppHeader from "../components/Header/AppHeader";
import { useSocket } from "../Context/SocketContext";
import { useUser } from "../Context/UserContext";
import { getChatRoomData } from "../services/http/chat/getChatRoomData";
// import { useTheme } from "@react-navigation/native";

export default function ChannelChatScreen({ navigation, route }) {
  const [openForm, setOpenForm] = useState(false);
  const flatListRef = useRef();
  const paperTheme = useTheme();
  const [message, setMessage] = useState("");
  const [dynamicRoomData, setDynamicRoomData] = useState(null);
  const [userToInvite, setUserToInvite] = useState("");
  const [userList, setUserList] = useState([]);
  const [socketState, socketAction] = useSocket();
  const [userState, userAction] = useUser();
  const { user } = userState;
  const { roomData } = socketState;
  const { roomID } = route.params;

  const getRoomData = async (roomID) => {
    const data = await getChatRoomData(roomID);
    return data;
  };

  useEffect(() => {
    const data = roomData.find((room) => room._id === roomID);
    console.log("data", data);
    if (data) {
      setDynamicRoomData(data);
    } else {
      console.log("jefre", roomID);
      const data = getRoomData(roomID);
      setDynamicRoomData(data);
    }
  }, [route.params]);

  useEffect(() => {
    socketState.socket.on("newMessage", (mes) => {
      console.log("messx", mes);
      socketAction.setNewMessage(mes);
    });
    return () => socketState.socket.off("newMessage");
  }, []);

  useEffect(() => {
    socketState.socket.once("receiveInvite", () =>
      console.log("recieved and invite")
    );
    return () => socketState.socket.off("receiveInvite");
  }, []);

  const handleSendMessage = () => {
    socketState.socket.emit("newMessage", {
      message,
      roomID,
      date: Date.now(),
    });
    setMessage("");
  };

  const handleAddUserToInvite = (user) => {
    setUserList((prev) => [...prev, user]);
    setUserToInvite("");
  };

  const handleSubmitUsers = () => {
    socketState.socket.emit("sendInvite", {
      guids: userList,
      roomID,
    });
  };

  const handleDismissModal = () => {
    setUserToInvite("");
    setUserList("");
    setOpenForm(false);
  };

  return (
    <View style={styles.container}>
      <Portal>
        <Modal
          visible={openForm}
          onDismiss={() => handleDismissModal()}
          contentContainerStyle={{ padding: 10, backgroundColor: "white" }}
        >
          <Headline style={{ padding: 10, margin: 5 }}>
            Invite member to group
          </Headline>
          <TextInput
            value={userToInvite}
            onChangeText={(t) => setUserToInvite(t)}
            style={{ margin: 10 }}
          />
          <Paragraph>Currently Inviting: </Paragraph>
          <FlatList
            data={userList}
            keyExtractor={(_, index) => index.toString()}
            renderItem={({ item }) => {
              return <Paragraph>{item}</Paragraph>;
            }}
          />
          <Button onPress={() => handleAddUserToInvite(userToInvite)}>
            Add User To Invite List
          </Button>
          <Button mode="contained" onPress={() => handleSubmitUsers()}>
            Invite Users
          </Button>
        </Modal>
      </Portal>
      {dynamicRoomData && (
        <Appbar.Header style={{ backgroundColor: paperTheme.background }}>
          <Appbar.Action
            icon="dots-vertical"
            onPress={() => navigation.toggleDrawer()}
          />
          <Appbar.Content
            title={dynamicRoomData ? dynamicRoomData.name : "Channel"}
            // subtitle={`Members online: ${membersOnline}`}
            style={{ marginLeft: 10 }}
          />
          <FAB
            style={styles.fab}
            small
            icon="plus"
            color="black"
            onPress={() => setOpenForm(true)}
            animated={true}
          />
        </Appbar.Header>
      )}
      <View style={styles.chatBox}>
        {dynamicRoomData && dynamicRoomData.messages.length > 0 && (
          <>
            <FlatList
              ref={flatListRef}
              initialNumToRender={100}
              onContentSizeChange={() =>
                flatListRef.current.scrollToEnd({ animated: true })
              }
              onLayout={() =>
                flatListRef.current.scrollToEnd({ animated: true })
              }
              data={dynamicRoomData.messages}
              keyExtractor={(item, index) => item.guid + index}
              renderItem={({ item }) => {
                let align = item.guid === user.guid ? "flex-end" : "flex-start";
                return (
                  <Card key={(item, index) => index + item.guid}>
                    <Card.Content>
                      <Paragraph style={{ alignSelf: align }}>
                        {item.message}
                      </Paragraph>
                    </Card.Content>
                  </Card>
                );
              }}
            />
          </>
        )}
      </View>

      <View style={styles.chatcontainer}>
        <Button style={styles.button} onPress={() => handleSendMessage()}>
          Send
        </Button>

        <TextInput
          style={styles.inputBar}
          title="chat"
          value={message}
          onChangeText={(t) => setMessage(t)}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  chatcontainer: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "column",
  },
  inputBar: {
    margin: 30,
    height: 100,
    width: "90%",
  },
  button: {
    alignSelf: "flex-end",
  },
  chatBox: {
    flex: 3,
    margin: 20,
  },
  fab: {
    margin: 16,
    backgroundColor: "orange",
  },
});
