import React, { useState } from "react";
import {
  Card,
  Headline,
  TextInput,
  Subheading,
  Button,
  Text,
} from "react-native-paper";
import { StyleSheet } from "react-native";
import AppHeader from "../components/Header/AppHeader";
import { useSocket } from "../Context/SocketContext";

export default function CreateChannelForm(props) {
  const [socketState, socketAction] = useSocket();
  const [userToAdd, setUserToAdd] = useState("");
  const [userList, setUserList] = useState([]);
  const [name, setName] = useState("");

  const handleAddUserToList = (user) => {
    setUserList((prev) => [...prev, user]);
  };

  const handleCreateRoom = async () => {
    socketAction.createNewRoom(name, userList).then(() => {
      props.navigation.toggleDrawer();
    });
    // this will be changed to navigate to the specific channel screen
    // once that is up and running
  };

  return (
    <>
      <AppHeader
        {...props}
        title={"Channel Creator"}
        leftIcon={"dots-vertical"}
        leftAction={() => props.navigation.toggleDrawer()}
      />
      <Card style={styles.container}>
        <Card.Content>
          <Headline style={styles.header}>Create a New Channel</Headline>
          <Subheading>Start by naming your channel: </Subheading>
          <TextInput
            value={name}
            onChangeText={(t) => setName(t)}
            title="Name Your Channel"
          />
        </Card.Content>
        <Card.Content style={styles.divider}></Card.Content>
        <Card.Content>
          <Subheading>Then invite people to the channel: (Or Don't)</Subheading>
          <TextInput
            value={userToAdd}
            title="Name Your Channel"
            onChangeText={(t) => setUserToAdd(t)}
          />
          <Button onPress={() => handleAddUserToList(userToAdd)}>
            Add User To Invite
          </Button>
          <Subheading>Currently Inviting: </Subheading>
          {userList.length > 0 &&
            userList.map((u) => {
              return <Subheading>{u}</Subheading>;
            })}
        </Card.Content>
        <Card.Content style={styles.divider}></Card.Content>
        <Card.Content>
          <Button onPress={() => handleCreateRoom()} mode="contained">
            Create Your New Channel!
          </Button>
        </Card.Content>
      </Card>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  header: {
    color: "black",
    alignSelf: "center",
    padding: 20,
  },
  divider: {
    margin: 30,
  },
});
