import React, { createContext } from "react";
import { getUser } from "../components/Util/getUser";
import { getUserMembership } from "../services/http/chat/getUserMembership";

export const UserContext = createContext({});
export const useUser = () => React.useContext(UserContext);

export default function UserProvider({ children }) {
  const [userState, userDispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "UPDATE_AVATAR":
          return {
            ...prevState,
            avatar: action.val,
          };
        case "SET_USER":
          return {
            ...prevState,
            user: action.val,
          };
      }
    },
    {
      avatar:
        "https://cdn.pixabay.com/photo/2016/04/01/11/25/avatar-1300331_1280.png",
      user: null,
    }
  );

  const userAction = {
    updateAvatar: (avatar) =>
      userDispatch({ type: "UPDATE_AVATAR", val: avatar }),
    setUser: (user) => {
      userDispatch({ type: "SET_USER", val: user });
    },
  };

  return (
    <UserContext.Provider value={[userState, userAction]}>
      {children}
    </UserContext.Provider>
  );
}
