import React from 'react';
import { StyleSheet, SafeViewArea, View, Text } from 'react-native'
import { useTheme, Appbar } from 'react-native-paper'


export default function AppHeader(props) {
  const paperTheme = useTheme()
  
  return (
    <Appbar.Header style={{ backgroundColor: paperTheme.background }}>
      <Appbar.Action icon={props.leftIcon} onPress={props.leftAction} />
      <Appbar.Content title={props.title} subtitle={props.subtitle} style={{ marginLeft: 10 }} />
      <Appbar.Action icon={props.rightIcon} onPress={props.rightAction} />
    </Appbar.Header>
  )
}

const styles = StyleSheet.create({

})