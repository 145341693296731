import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    channelScreenSaveAreaView: {
        backgroundColor: 'white',
      },
      channelScreenContainer: {flexDirection: 'column', height: '100%'},
      container: {
        flex: 1,
      },
      drawerNavigator: {
        backgroundColor: '#000000',
        width: 350
      },
      drawerContainer: {
        flex: 1,
        position: "absolute",
        left: 0,
        right: 0
      },
      chatContainer: {
        backgroundColor: 'white',
        flexGrow: 1,
        flexShrink: 1,
        height: '70%',
        marginTop:10,
        marginBottom: 10
      },
      channelHeadercontainer: {
        padding: 15,
        flexDirection: 'row',
        backgroundColor: 'white',
        justifyContent: 'space-between',
        borderBottomWidth: 0.5,
        borderBottomColor: 'grey',
      },
      leftContent: {
        flexDirection: 'row',
      },
      hamburgerIcon: {
        fontSize: 27,
      },
      channelHeaderTitle: {
        color: 'black',
        marginLeft: 10,
        fontWeight: '900',
        fontSize: 17,
      },
      rightContent: {
        flexDirection: 'row',
        marginRight: 10,
      },
      searchIconContainer: {marginRight: 15, alignSelf: 'center'},
      searchIcon: {
        height: 18,
        width: 18,
      },
      menuIcon: {
        height: 18,
        width: 18,
      },
      menuIconContainer: {alignSelf: 'center'},
      inputBoxContainer: {
        flexDirection: 'column',
        flex: 1,
        height: 60,
      },
      textboxContainer: {
        flexDirection: 'row', 
        justifyContent: 'space-between',
        marginLeft: 5,
        borderTopWidth: 1,
        borderTopColor: '#3E3939'
    },
      row: {flexDirection: 'row'},
      sendMessageButton: {
        marginLeft: 0,
      },
      inputBox: {
        width: 330,
        backgroundColor: '#ffffff',
        paddingLeft: 10
      },
      channelContainer: {
        paddingLeft: 5,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '100%',
      },
      headerContainer: {
        padding: 10,
        marginRight: 10,
      },
      inputSearchBox: {
        backgroundColor: '#2E2A2A',
        padding: 10,
      },
      sectionList: {
        flexGrow: 1,
        flexShrink: 1,
      },
      groupTitleContainer: {
        flexDirection: 'row',
        flex: 5,
      },
      groupTitle: {
        color: 'white',
        fontWeight: '500',
        fontSize: 12
      },
      onlineCircle: {
        width: 10,
        height: 10,
        borderRadius: 100 / 2,
        backgroundColor: 'green',
      },
      offlineCircle: {
        width: 10,
        height: 10,
        borderRadius: 100 / 2,
        borderColor: 'white',
        borderWidth: 0.3,
        backgroundColor: 'transparent',
      },
      channelRow: {
        padding: 3,
        paddingLeft: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: 6,
        marginRight: 5,
      },
      channelItemTitleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
      },
      unreadChannelTitle: {
        marginLeft: 3,
        fontWeight: 'bold',
        padding: 5,
      },
      channelTitle: {
        padding: 5,
        fontWeight: '300',
        paddingLeft: 10,
        color: '#ffffff'
      },
      channelTitlePrefix: {
        fontWeight: '300',
        color: '#ffffff'
      },
      unreadMentionsContainer: {
        backgroundColor: 'red',
        borderRadius: 20,
        alignSelf: 'center',
        marginRight: 20,
      },
      unreadMentionsText: {
        color: '#ffffff',
        padding: 3,
        paddingRight: 6,
        paddingLeft: 6,
        fontSize: 15,
        fontWeight: '900',
      },
      blindlyGroupsHeaderContainer: {
        padding: 5,
        flexDirection: 'row',
        backgroundColor: '#ffffff',
        justifyContent: 'flex-start',
        width: 500,
        position: 'relative',
        height: 20,
      },
      blindlyGroupsTitle: {
        flex: 0,
        width: 150,
        height: 20, 
        position: 'absolute',
        left: '30%',
      },
      BlindlyGroupsHeaderText: {
        color: 'black',
        marginLeft: 10,
        fontWeight: '900',
        fontSize: 17,
      },
      usersOnline: {
        width: 100,
        height: 20,
        position: 'absolute',
        left: '58%',
      },
      defaultAvatar: {
        width: 50,
        height: 50,
        backgroundColor: '#3E3939',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.8,
        shadowRadius: 1,
        elevation: 1,
      },
      initials: {
        fontSize: 30,
        textAlign: 'center',
        top: 5,
        color: '#ffffff'
      },
      messageContainer: {
        margin: 5,
        padding: 5,
        flexDirection: 'row',
        marginRight: 90
      },
      avatarContainer: {
        marginRight: 15
      },
      messageNameAndDate: {
        flexDirection: 'row'
      },
      messageAuthor: {
        marginRight: 10,
        marginBottom: 6,
        fontWeight: '700',
        fontSize: 12
      },
      messageTimeStamp: {
        fontSize: 10,
        color: '#3E3939'
      },
      messageTextContainer: {
        flexDirection: 'column',
        flex: 0.8
      },
      messageText: {
        fontSize: 14,
      },
      heartbeatText: {
        color: '#ffffff',
        fontSize: 16,
        fontWeight: '500',
        textAlign: 'center',
        marginBottom: 10
      },
      channelListContainer: {
        flexDirection: "row",
        padding: 2,
        borderBottomColor: '#3E3939',
        borderBottomWidth: 0.3,
        marginBottom: 7,
        backgroundColor: '#2E2A2A',
        justifyContent: "center",
        alignItems: 'center'
      },
      addChannelButtonContainer: {
        flexDirection: "row",
        flex: 1,
        marginRight: 5
      },
      addChannelButton: {
        fontSize: 10,
      }
})