import React from 'react'
import { ScrollView, Alert, StyleSheet, SafeAreaView, View, Text } from 'react-native'
import { useTheme, Button, TextInput } from 'react-native-paper'
import Center from '../components/Prebuilt/Center'
import AlertView from '../components/Prebuilt/AlertView'
import { validateSignIn } from '../validation/validationSchemas'
import { getAccessToken, clearTokens } from '../services/auth/AuthRoutes';
import { useAuth } from '../Context/AuthContext'
import { loginUser } from '../services/http/blindlyGroups/AuthDao';
import { setTokens } from '../services/auth/AuthRoutes';
import axios from 'axios'
import BlindlyGroupsApi from '../services/http/config';

const SERVICE = BlindlyGroupsApi.auth;


export default function LoginScreen(props) {
  const [authState, authAction] = useAuth()
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('')
  const [alert, setAlert] = React.useState(null)
  const [success, setSuccess] = React.useState(false)
  const paperTheme = useTheme()

  React.useEffect(() => {
      const registeredEmail = props.route.params?.email
      if (registeredEmail) {
         setEmail(props.route.params?.email)
      }
   }, [props.route.params]);

  const handleSignIn = () => {
      setAlert(null)

      validateSignIn(email.trim(), password,
         error => {
            setAlert(error)
            setSuccess(false)
         },
         success => {
            setSuccess(true)
            const doLogin = async () => {
               try {
                  const result = await loginUser(email, password);
                  const { accessToken, refreshToken } = result.data;
                  if (accessToken) {
                     await setTokens(accessToken, refreshToken);
                     console.log('You are online!')
                     setTimeout(() => {
                        authAction.signIn(accessToken)
                     }, 1000)
                  }
               } catch (e) {
                  let message = 'Sign In failed. Try again'
                  setAlert(message)
                  console.log(message)
                  setSuccess(false)
               };
            };
            doLogin();
         });
   };

  return (
      <ScrollView style={styles.container}>
         <Text style={[styles.inputTitle, { color: paperTheme.dark ? '#fff' : '#000' }]}>Sign In with your Email</Text>

         <AlertView
            title={alert}
            onClose={() => setAlert(null)}
         />

         <TextInput
            testID="loginEmail"
            name="email"
            label="Email"
            value={email}
            style={styles.textInput}
            onChangeText={setEmail}
            autoCapitalize={'none'}
         />

         <TextInput
            testID="loginPassword"
            label="Password"
            value={password}
            style={styles.textInput}
            onChangeText={setPassword}
            secureTextEntry={true}
            autoCapitalize={'none'}
         />

         <Button
            testID="loginBtn"
            title='Sign In'
            mode='contained'
            style={styles.signInButton}
            loading={success}
            onPress={handleSignIn}
            disabled={success}>
            Sign In
         </Button>

         <Button
            testID="registerBtn"
            title='Register'
            mode='outlined'
            style={styles.registerButton}
            onPress={() => props.navigation.push('Sign Up')}>
            New User? Register Here
         </Button>

      </ScrollView>
   )
};

const styles = StyleSheet.create({
  container: {
    margin: 10
  },
  inputTitle: {
    fontWeight: '600',
    margin: 10
  },
  textInput: {
    margin: 5
  },
  signInButton: {
    margin: 10,
    marginTop: 15
  },
  registerButton: {
    margin: 10
  },
});
