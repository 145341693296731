import React, { useState } from "react";
import { StyleSheet, View, Text, FlatList } from "react-native";
import {
  useTheme,
  Appbar,
  Avatar,
  Button,
  Drawer,
  Card,
  Headline,
  Paragraph,
  Switch,
  TouchableRipple,
  Portal,
  TextInput,
  Modal,
  Subheading,
} from "react-native-paper";
import { DrawerContentScrollView } from "@react-navigation/drawer";
import { AntDesign } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useStore } from "../../Context/StoreContext";
import { useUser } from "../../Context/UserContext";
import { useAuth } from "../../Context/AuthContext";
import { useSocket } from "../../Context/SocketContext";
import ModalForm from "../Prebuilt/ModalForm";

export default function AppDrawerContent({ navigation }) {
  const paperTheme = useTheme();
  const [storeState, storeAction, socketEvents] = useStore();
  const [userState, userAction] = useUser();
  const [authState, authAction] = useAuth();
  const [socketState, socketAction] = useSocket();
  const { createNewRoom } = socketAction;
  const toggleTheme = () => storeAction.toggleTheme();
  const doSignOut = () => {
    storeAction.clearUserNamespace()
    authAction.signOut()
  };
  const [openForm, setOpenForm] = useState(false);
  const [userToInvite, setUserToInvite] = useState("");
  const [userList, setUserList] = useState([]);
  let membersOnline = [];

  React.useEffect(() => {
    storeAction.setStoredTheme(paperTheme.dark);
  }, [paperTheme.dark]);

  // React.useEffect(() => {
  //   membersOnline = socketState ? socketState.onlineMembers : [];
  // }, [socketState.onlineMembers]);

  const handleAddUserToInvite = (user) => {
    setUserList((prev) => [...prev, user]);
    setUserToInvite("");
  };

  const handleSubmitUsers = () => {
    socketState.socket.emit("sendNspInvite", {
      guids: userList,
    });
    socketState.socket.on("inviteNspSuccess", () => console.log("succee nsp"));
    setUserToInvite("");
    setUserList("");
    setOpenForm(false);
  };

  const handleDismissModalForm = () => {
    setUserToInvite("");
    setUserList("");
    setOpenForm(false);
  };

  const acceptRoomInvite = async (inviteID) => {
    console.log(inviteID);
    socketState.socket.emit("acceptInvite", { roomID: inviteID });
    navigation.navigate("ChannelChatScreen", { roomID: inviteID });
  };

  return (
    <View
      style={{ flex: 1, backgroundColor: paperTheme.background, marginTop: 15 }}
    >
      <Portal>
        <Modal
          visible={openForm}
          onDismiss={() => handleDismissModalForm()}
          contentContainerStyle={{ padding: 10, backgroundColor: "white" }}
        >
          <Headline style={{ padding: 10, margin: 5 }}>
            Invite member to group
          </Headline>
          <TextInput
            value={userToInvite}
            onChangeText={(t) => setUserToInvite(t)}
            style={{ margin: 10 }}
          />
          <Paragraph>Currently Inviting: </Paragraph>
          <FlatList
            data={userList}
            keyExtractor={(_, index) => index.toString()}
            renderItem={({ item }) => {
              return <Paragraph>{item}</Paragraph>;
            }}
          />
          <Button onPress={() => handleAddUserToInvite(userToInvite)}>
            Add User To Invite List
          </Button>
          <Button mode="contained" onPress={() => handleSubmitUsers()}>
            Invite Users
          </Button>
        </Modal>
      </Portal>
      <DrawerContentScrollView>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {/* <View style={{ marginLeft: 10 }}>
            <Avatar.Image source={{ uri: userState.avatar }} size={40} />
          </View> */}
          <View style={{ marginLeft: 10 }}>
            <Paragraph
              style={{
                color: paperTheme.dark ? "#fff" : "#000",
              }}
            >
              NSP_ID: {storeState && storeState.userNamespace}
            </Paragraph>
            <Paragraph
              style={{
                color: paperTheme.dark ? "#fff" : "#000",
              }}
            >
              GUID: {userState.user && userState.user.guid}
            </Paragraph>
            <Text style={{ color: paperTheme.dark ? "#fff" : "#000" }}>
              Online: {membersOnline.length}
            </Text>
            <Button onPress={() => setOpenForm(true)}>Invite To NSP</Button>
          </View>
        </View>
        <Drawer.Section style={{ paddingTop: 10 }} />

        <Drawer.Section>
          <Drawer.Item
            icon={({ color, size }) => (
              <AntDesign
                name="wechat"
                color={paperTheme.dark ? "#fff" : "#000"}
                size={size}
              />
            )}
            label="Blindly Chat"
            onPress={() => navigation.navigate("Chat")}
          />
          <Drawer.Item
            icon={({ color, size }) => (
              <AntDesign
                name="message1"
                color={paperTheme.dark ? "#fff" : "#000"}
                size={size}
              />
            )}
            label="Direct Message"
            onPress={() => navigation.navigate("DirectMessages")}
          />
          <Drawer.Item
            icon={({ color, size }) => (
              <AntDesign
                name="menuunfold"
                color={paperTheme.dark ? "#fff" : "#000"}
                size={size}
              />
            )}
            label="Threads"
            onPress={() => navigation.navigate("Threads")}
            drawerLabel={{ color: paperTheme.text }}
          />
          <Drawer.Item
            icon={({ color, size }) => (
              <AntDesign
                name="profile"
                color={paperTheme.dark ? "#fff" : "#000"}
                size={size}
              />
            )}
            label="Profile"
            onPress={() => navigation.navigate("Profile")}
            drawerLabel={{ color: paperTheme.text }}
          />
          <Drawer.Item
            icon={({ color, size }) => (
              <AntDesign
                name="setting"
                color={paperTheme.dark ? "#fff" : "#000"}
                size={size}
              />
            )}
            label="Settings"
            onPress={() => navigation.navigate("Settings")}
            drawerLabel={{ color: paperTheme.text }}
          />
          <Drawer.Item
            icon={({ color, size }) => (
              <AntDesign
                name="contacts"
                color={paperTheme.dark ? "#fff" : "#000"}
                size={size}
              />
            )}
            label="Support"
            onPress={() => navigation.navigate("Support")}
            drawerLabel={{ color: paperTheme.text }}
          />
        </Drawer.Section>

        <Drawer.Section title="Options">
          <TouchableRipple onPress={toggleTheme} style={{ marginBottom: 5 }}>
            <View style={styles.options}>
              <Text style={{ color: paperTheme.dark ? "#fff" : "#000" }}>
                Dark Theme
              </Text>
              <View pointerEvents="none">
                <Switch value={paperTheme.dark} />
              </View>
            </View>
          </TouchableRipple>
        </Drawer.Section>

        {/* We need to map over all rooms and put them here */}
        <Drawer.Section title="Channels">
          <Drawer.Item
            label=" + Create New Channel"
            onPress={() => navigation.navigate("CreateChannelForm")}
            style={{ borderBottomColor: "black", borderWidth: 1, margin: 2 }}
          />
          {socketState &&
            socketState.roomData !== undefined &&
            socketState.roomData.length > 0 &&
            socketState.roomData.map((room) => {
              return (
                <Drawer.Item
                  key={room._id}
                  label={room.name}
                  onPress={() =>
                    navigation.navigate("ChannelChatScreen", {
                      roomID: room._id,
                    })
                  }
                  drawerLabel={{ color: paperTheme.text }}
                />
              );
            })}
        </Drawer.Section>
        <Drawer.Section title="invites">
          {socketState &&
            // socketState.invites !== undefined &&
            socketState.invites.length > 0 &&
            socketState.invites.map((inv) => {
              return (
                <Drawer.Item
                  key={inv.inviteID}
                  label={inv._id}
                  style={{
                    borderBottomColor: "black",
                    borderWidth: 1,
                    margin: 2,
                  }}
                  drawerLabel={{ color: paperTheme.text }}
                  onPress={() => acceptRoomInvite(inv.inviteID)}
                />
              );
            })}
        </Drawer.Section>
      </DrawerContentScrollView>

      <Drawer.Section style={styles.bottomDrawerSection}>
        <Drawer.Item
          icon={({ color, size }) => (
            <AntDesign
              name="leftcircleo"
              color={paperTheme.dark ? "#fff" : "#000"}
              size={size}
            />
          )}
          label="Sign Out"
          onPress={doSignOut}
        />
      </Drawer.Section>
    </View>
  );
}

const styles = StyleSheet.create({
  bottomDrawerSection: {
    paddingTop: 5,
    marginBottom: 15,
    borderTopColor: "#f4f4f4",
    borderTopWidth: 1,
  },
  options: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
});
