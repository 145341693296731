import { getSecureAxios } from '../axios';
import BlindlyGroupsApi from '../config';

const SERVICE = BlindlyGroupsApi.auth;

export const loginUser = (username, password) =>
  new Promise((resolve, reject) =>
    getSecureAxios()
      .then(axios =>
        axios.post(`${SERVICE}/login`, {
          email: username,
          password: password
        })
      )
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err);
      })
  );

export const registerUser = (username, password) =>
  new Promise((resolve, reject) =>
    getSecureAxios()
      .then(axios =>
        axios.post(`${SERVICE}/register`, {
          email: username,
          password: password
        })
      )
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        console.error("Registration error: ", err);
        reject(err)
      })
  )