import React, { createContext } from "react";
import { useTheme } from "react-native-paper";
import AsyncStorage from "@react-native-async-storage/async-storage";
export const StoreContext = createContext({});
export const useStore = () => React.useContext(StoreContext);

export default function StoreProvider(props) {
  const paperTheme = useTheme();
  const [storeState, storeDispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "MEMBERS_ONLINE":
          return {
            ...prevState,
            membersOnline: action.val,
          };
        case "SET_NAMESPACE":
          return {
            ...prevState,
            userNamespace: action.val,
          };
      }
    },
    {
      membersOnline: 0,
      userNamespace: null,
    }
  );

  React.useEffect(() => {
    storeAction.getStoredTheme();
  }, []);

  const storeAction = {
    setStoredTheme: async (theme) => {
      await AsyncStorage.setItem("QUARIUM_THEME", JSON.stringify(theme));
    },
    getStoredTheme: async () => {
      try {
        if (JSON.parse(await AsyncStorage.getItem("QUARIUM_THEME"))) {
          storeAction.toggleTheme();
        }
      } catch (e) {
        console.log("FAILED READING STORED THEME", e);
      }
    },
    toggleTheme: () => props.toggleTheme(),
    updateMembersOnline: (online) =>
      storeDispatch({ type: "MEMBERS_ONLINE", val: online }),

    setUserNamespace: (nsp) => {
      storeDispatch({ type: "SET_NAMESPACE", val: nsp });
    },
    clearUserNamespace: () => {
      storeDispatch({ type: "SET_NAMESPACE", val: null });
    }
  };

  return (
    <StoreContext.Provider value={[storeState, storeAction]}>
      {props.children}
    </StoreContext.Provider>
  );
}
