const BlindlyGroups = require("../../../package.json").api;

const BlindlyGroupsApi = {
  main: BlindlyGroups,
  auth: `${BlindlyGroups}/api/auth`,
  chat: `${BlindlyGroups}/api/chat`,
  profile: `${BlindlyGroups}/api/profile`,
  socket: `${BlindlyGroups}`
};

export default BlindlyGroupsApi;