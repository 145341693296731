import { getSecureAxios } from "../axios";
import BlindlyGroupsApi from "../config";
import chatRoutes from "./routes";

const SERVICE = BlindlyGroupsApi.chat;

export async function getUserMembership(user) {
  const membership = new Promise((resolve, reject) =>
    getSecureAxios()
      .then((axios) =>
        axios.post(`${SERVICE}/user/get`, {
          user,
        })
      )
      .then((res) => resolve(res.data))
      .catch((err) => {
        console.error("Error Getting User Membership", err);
        reject(err);
      })
  );
  return membership;
}
