import AsyncStorage from '@react-native-async-storage/async-storage';

const ACCESS_TOKEN_KEY = 'accessJWT';
const REFRESH_TOKEN_KEY = 'refreshJWT';

export const getAccessToken = () => AsyncStorage.getItem(ACCESS_TOKEN_KEY);

export const getRefreshToken = () => AsyncStorage.getItem(REFRESH_TOKEN_KEY);

const setAccessToken = (accessToken) => AsyncStorage.setItem(ACCESS_TOKEN_KEY, accessToken);

const setRefreshToken = (refreshToken) => AsyncStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

export const setTokens = (accessToken, refreshToken) => Promise.all([setAccessToken(accessToken), setRefreshToken(refreshToken)]);

const clearAccessToken = () => AsyncStorage.removeItem(ACCESS_TOKEN_KEY);

const clearRefreshToken = () => AsyncStorage.removeItem(REFRESH_TOKEN_KEY);

export const clearTokens = () => Promise.all([clearAccessToken(), clearRefreshToken()]);