import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { useTheme, Button } from 'react-native-paper'


export default function AlertView(props) {
  if (!props.title) return null
  const paperTheme = useTheme()

  return (
    <View style={styles.alertView}>
      <Text style={[styles.alertTitle, { color: paperTheme.dark ? '#fff' : '#000' }]}>{props.title}</Text>
      <Button
        style={styles.alertButton}
        onPress={props.onClose}>
        <Text style={{ color: paperTheme.dark ? '#fff' : '#000' }}>OK</Text>
      </Button>
    </View>
  )
}

const styles = StyleSheet.create({
  alertView: {
    padding: 8,
    borderWidth: 1,
    borderColor: 'red',
    width: '100%',
    borderRadius: 5,
    marginBottom: 5
  },
  alertTitle: {
    fontWeight: '700'
  },
  alertButton: {
    marginTop: 10
  },
})