import React from 'react';
import { StyleSheet, ScrollView, View, Text, SafeAreaView, Alert } from 'react-native'
import { useTheme, Appbar, Avatar, Button, Card, Title, Paragraph } from 'react-native-paper'
import { useActionSheet } from '@expo/react-native-action-sheet'
import { useAuth } from '../Context/AuthContext'


export default function ProfileScreen(props) {
  const [authState, authAction] = useAuth()
  const { showActionSheetWithOptions } = useActionSheet();
  const paperTheme = useTheme()
  const doSignOut = () => authAction.signOut()

  const openActionSheet = () => {
    // Same interface as https://facebook.github.io/react-native/docs/actionsheetios.html
    const options = ['Update Profile', 'Cancel', 'Sign Out',];
    const destructiveButtonIndex = 0;
    const cancelButtonIndex = 1;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      buttonIndex => {
        // Do something here depending on the button index selected
        console.log(buttonIndex)
        switch (buttonIndex) {
          case 2:
            doSignOut()
        }
      },
    );
  };

  return (
    <View style={{ flex: 1 }}>
      <Appbar.Header style={{ backgroundColor: paperTheme.background }}>
        <Appbar.Action icon='dots-vertical' onPress={() => props.navigation.toggleDrawer()} />
        <Appbar.Content title='Profile' subtitle='Member name' />
        <Appbar.Action icon='alert' color='orange' onPress={() => Alert.alert('Notice: We\'re still working . .')} />
        <Appbar.Action icon='account-settings' onPress={openActionSheet} />
      </Appbar.Header>

      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView>
          <View style={{ alignItems: 'center' }}>
            <Text style={{ fontWeight: '700', marginTop: 10, color: paperTheme.dark ? '#fff' : '#000' }}>
              {props.route.name}
            </Text>
            <Text style={{ color: paperTheme.dark ? '#fff' : '#000' }}>{JSON.stringify(props, null, 2)}</Text>
          </View>
        </ScrollView>
      </SafeAreaView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 10,
  },
})