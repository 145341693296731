import React, { createContext, useEffect, useState } from "react";
import { getAccessToken } from "../services/auth/AuthRoutes";
import { getUser } from "../../src/components/Util/getUser";
import { useStore } from "./StoreContext";
import io, { Manager } from "socket.io-client";
import BlindlyGroupsApi from "../services/http/config";
import { useUser } from "./UserContext";
import { getChatRoomData } from "../services/http/chat/getChatRoomData";
import update from "immutability-helper";

const defaultSocketContext = {
  establishSocket: () => {},
};

// TODO : unsubscribe to socket events in useEffects -> socket.off('event name)

export const SocketContext = createContext(defaultSocketContext);
export const useSocket = () => React.useContext(SocketContext);

export default function SocketProvider({ children }) {
  const [connected, setConnected] = useState(false);
  const [storeState] = useStore();
  const [userState] = useUser();

  useEffect(() => {
    if (!connected && storeState.userNamespace !== null) {
      console.log("connecting.....");
      establishSocket(userState.user);
    }
    return () => socketEvents.emitDisconnect();
  }, [storeState.userNamespace]);

  useEffect(() => {
    if (connected) {
      socketState.socket.on("receiveInvite", (inv) => {
        console.log("inv", inv);
      });
    }
  }, []);

  const [socketState, socketDispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "SET_SOCKET":
          return {
            ...prevState,
            socket: action.val,
          };
        case "SET_ROOM_DATA":
          return {
            ...prevState,
            roomData: action.val,
          };
        case "CREATE_NEW_ROOM":
          return {
            ...prevState,
            roomData: [...prevState.roomData, action.val],
          };
        case "NEW_MESSAGE":
          let index = prevState.roomData.findIndex(
            (m) => m._id === action.val.roomID
          );
          return update(prevState, {
            roomData: {
              [index]: {
                messages: { $push: [action.val] },
              },
            },
          });
        case "SET_INCOMING_INVITE":
          return {
            ...prevState,
            invites: [...prevState.invites, action.val],
          };
        case "SET_INVITES":
          return {
            ...prevState,
            invites: action.val,
          };
        case "SET_ONLINE_MEMBERS":
          return {
            ...prevState,
            onlineMembers: action.val,
          };
      }
    },
    {
      socket: null,
      roomIds: null,
      invites: [],
      roomData: [],
      onlineMembers: [],
    }
  );

  const url = `${BlindlyGroupsApi.socket}/${storeState.userNamespace}`;

  async function establishSocket(currentUser) {
    const accessToken = await getAccessToken();

    const auth = {
      token: `Bearer ${accessToken}`,
    };

    const socket = io(
      `${BlindlyGroupsApi.socket}/${storeState.userNamespace}`,
      {
        transports: ["websocket"],
        auth,
      }
    );

    socketAction.setSocket(socket);

    socket.on("connect", (id) => {
      console.info("Connected to chat server ");
      setConnected(true);
    });

    socket.emit("login", currentUser);
    // need to handle the no login case where the login fails to connect to socket
    socket.on("login", (ids) => {
      console.log("ids: ", ids);
      socketAction.setOnlineMembers(ids);
    });

    socket.on("userMembership", async (membership) => {
      console.log("member", membership);
      if (membership.roomIDs.length > 0) {
        let rooms = [];
        membership.roomIDs.forEach(async (r) => {
          const data = await getChatRoomData(r);
          // i shouldn't have to filter this out on the front end
          // also need to filter if user is in invited array in room
          if (data.room.nsp === storeState.userNamespace) {
            rooms.push(data.room);
          }
        });
        socketAction.setRoomData(rooms);
      }
      if (membership.invites.length > 0) {
        socketAction.setInvites(
          membership.invites.filter((inv) => inv.inviteType === "room")
        );
      }
      console.log(socketState.invites);
    });
  }

  async function createNewRoom(room, users) {
    const newRoom = new Promise((resolve, reject) => {
      const data = {
        guids: users,
        name: room,
      };
      socketEvents.emitNewRoomInvite(data);
      socketState.socket.on("inviteSuccess", (room) => {
        resolve(room);
      });
    });
    return newRoom;
  }

  async function sendNspInvite(guids) {
    if (connected) {
      socketEvents.emitNspInvite(guids);
      socketState.socket.once("inviteNspSuccess", () =>
        console.log("invite success")
      );
    }
  }

  const socketAction = {
    setSocket: (socket) => {
      socketDispatch({ type: "SET_SOCKET", val: socket });
    },
    setRoomData: (data) => {
      socketDispatch({ type: "SET_ROOM_DATA", val: data });
    },
    setInvites: (invites) => {
      console.log("inviteaction ", invites);
      socketDispatch({ type: "SET_INVITES", val: invites });
    },
    setIncomingInvite: (inv) => {
      socketDispatch({ type: "SET_INCOMING_INVITE", val: inv });
    },
    createNewRoom: async (room, users) => {
      const newRoom = await createNewRoom(room, users);
      socketDispatch({ type: "CREATE_NEW_ROOM", val: newRoom.room });
    },
    setNewMessage: (mes) => {
      socketDispatch({ type: "NEW_MESSAGE", val: mes });
    },
    sendNspInvite: async (users) => {
      await sendNspInvite(users);
    },
    setOnlineMembers: (users) => {
      console.log("USERS: ", users);
      socketDispatch({ type: "SET_ONLINE_MEMBERS", val: users });
    },
  };

  const socketEvents = {
    emitMessage: (mes) => {
      if (connected) socketState.socket.emit("newMessage", mes);
    },
    emitNspInvite: (guids) => {
      if (connected)
        socketState.socket.emit("sendNspInvite", { data: { guids } });
    },
    emitNewRoomInvite: (data) => {
      if (connected) socketState.socket.emit("sendInvite", data);
    },
    emitLogin: (currentUser) => {
      if (connected) socketState.socket.emit("login", currentUser);
    },
    emitAcceptRoomInvite: async (inviteID) => {
      if (connected)
        await socketState.socket.emit("acceptInvite", { data: { inviteID } });
    },
    emitDisconnect: () => {
      if (connected) socketState.socket.disconnect();
    },
  };

  return (
    <SocketContext.Provider value={[socketState, socketAction, socketEvents]}>
      {children}
    </SocketContext.Provider>
  );
}
